import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';

function Docs() {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={0} sx={{ p: 4, bgcolor: '#F3EFEE' }}>
                
                <Box sx={{ mb: 4 }}>
                    <Typography variant="body1" sx={{ 
                        mb: 2,
                        color: 'rgba(0, 0, 0, 0.8)',
                        fontFamily: "'Inter', sans-serif" 
                    }}>
                        5Tasks is a minimal and easy to use app that helps you focus on the most important tasks for the everyday life
                        <br /><br />it works offline, no need to sign up or log in, your data never leaves your device
                    </Typography>
                </Box>
                
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 500 
                    }}>
                        How to Use
                    </Typography>
                    <Box component="ol" sx={{ 
                        pl: 2,
                        '& li': { 
                            mb: 1,
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontFamily: "'Inter', sans-serif"
                        } 
                    }}>
                        <li>Write your task description & hit enter</li>
                        <li>Check to mark complete & click `X` to delete</li>
                    </Box>
                </Box>

                <Box>
                    <Typography variant="h6" gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: 500 
                    }}>
                        Tips
                    </Typography>
                    <Box component="ul" sx={{ 
                        pl: 2,
                        '& li': { 
                            mb: 1,
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontFamily: "'Inter', sans-serif"
                        } 
                    }}>
                        <li>Focus on the most important tasks</li>
                        <li>Add as many tasks, but we think 5 is enough</li>
                        <li>Review and reset your task list daily</li>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}

export default Docs;